import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BoComponent } from './bo/bo.component';
import { SharedModule } from 'primeng/api';
import { LoginComponent } from './bo/login/login.component';
import { BoModule } from '../app/bo/bo.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    loadChildren: () => import('../app/bo/bo.module').then(m => m.BoModule),
    component: BoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
