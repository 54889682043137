import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './shared/services/api/api.service';
import { AuthService } from './shared/services/auth/auth.service';
import { Columns } from './shared/models/columns.model';
import { MenuItem } from 'primeng/api';
import { SettingService } from './shared/services/model/setting/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'app-adv';
  menu!: MenuItem[];

  filterByStatus = 0;
  pageIndex = 0;
  user;
  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _router: Router,
    private _settingService: SettingService
  ) {}

  columns: Columns[] = Columns.createInitialData();
  selectedColumns!: Columns[];

  ngOnInit() {
    this._settingService.all();
    this.selectedColumns = this.columns;
    this.initMenu();
    // this.getCustomerFile();
    if(!this._authService.authenticated){
      this._router.navigate(["login"]);
    }
    this.user = this._authService.authenticated();
  }
  goToPage(page){
    this._router.navigate([page]);
  }

  initMenu() {
    this.menu = [
      { label: 'Accueil' },
      {
        label: "Liste des dossiers d'identification",
        children: [
          { label: 'A saisir'}
        ]
      }
    ];
  }
}
